import React, { Fragment, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Row, Col, Container } from 'react-bootstrap';
import '../../App.css';
// import axios from 'axios';

export default function FormikForm({title, subTitle, step, stepCount, handleStepChange, handleBack, handleNext}){

// function pay() {
//       return axios.post(`https://tango-checkout-api.herokuapp.com/api/mollie-payment`, {
//         data: {
//           method: localStorage.getItem('paymentOption')
//         }})
//       .then(result => {
//         const checkoutUrl = result.data._links.checkout.href;
//         window.location.replace(checkoutUrl);
//       })
//       .catch(error => console.log(error))
//     }
//
//     useEffect(() => {
//       pay()
//     }, [])

  return (
    <Fragment>
    <Container>
      <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <h1>{title}</h1>
            <h2>{subTitle}</h2>
          </Col>
      </Row>
      <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <p>
              De bezoeker zal deze pagina niet zien.
              Op deze pagina zal de bezoeker doorverwezen worden naar de Mollie betaling.
              De mollie koppeling werkt wanneer het prototype lokaal uitgevoerd wordt.
            </p>
            <p>
              Momenteel krijgt het prototype een CORS melding.
              Dit heeft waarschijnlijk te maken met het feit dat account die gebruik maken van Mollie eerst geverifieerd moeten worden.
              Deze verificatie gaat aan de hand een KvK nummer.
            </p>
            <p>
              Voor nu kunt u via de “Volgende” knop doorklikken naar de bedankpagina.
            </p>
          </Col>
      </Row>


    <div className="bottom_buttons">
    <Button disabled={step === 0} onClick={handleBack}>Vorige</Button>
    {/* Next & Finish button */}
    <Button variant="contained" color="primary" onClick={handleNext}>
      {step === stepCount - 1 ? 'Finish' : 'Volgende'}
    </Button>
    </div>
    </Container>
    </Fragment>
  )
}
