import React, { Fragment } from 'react';
import { Formik, Field } from 'formik';
import { Persist } from 'formik-persist';
import * as Yup from "yup";
import Error from "../Error";
import '../../App.css';

import Winkelwagen from "../Shoppingcard/index.jsx";
import PaymentPanel from "../PaymentPanel/index.jsx";
import { Row, Col, Container } from 'react-bootstrap';

import Button from '@material-ui/core/Button';
// import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';


const validationSchema = Yup.object().shape({
  cardnumber: Yup
    .string()
    .min(13, "Een creditcard nummer heeft minimaal 13 cijfers")
    .max(19, "Een creditcard nummer heeft maximaal 19 cijfers"),
  cardowner: Yup
    .string()
    .min(1, "Je naam is te kort.")
    .max(255, "Je naam is te lang.")
    .matches(/^[a-zA-Z]{1,}\s[a-zA-Z]{1}/, "Vul je voor en achternaam in."),
  expdateyear: Yup
    .string()
    .min(1, "Je naam is te kort.")
    .max(255, "Je naam is te lang."),
  cvc: Yup
    .string()
    .min(3, "Deze cvc code is te kort.")
    .max(3, "Deze cvc code is te lang."),
  bancontact_number: Yup
    .string()
    .min(17, "Een kaartnummer voor bancontact bevat 17 cijfers")
    .max(17, "Een kaartnummer voor bancontact bevat 17 cijfers"),
  discountcode: Yup
    .string()
    .max(14, "Voer een geldige kortingscode in. bv. 1234-1234-1234")
    .matches(/\d{4}\-\d{4}\-\d{4}/, "Voer een geldige kortingscode in. bv. 1234-1234-1234"),
})

  const { useState } = React;

  export default function FormikForm({state, handleStateChange, title, subTitle, showText, setShowText, step, stepCount, handleNext, handleBack}){
  const [paymentOption, setPaymentOption] = React.useState('iDeal');
  const [ currentState, setState] = useState({ shown: false });

  return (
    <Fragment>
      <Container>
        <Row>
          <Col lg={{ span: 4, offset: 0 }}>
            <Winkelwagen/>
          </Col>

      <Col lg={{ span: 8, offset: 0 }}>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>

      {/* Form */}
      <Formik
        initialValues={{
          cardnumber: "",
          cardowner: "",
          cvc:"",
          bancontact_number:"",
          discountcode:"",
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
            handleNext()
          }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (


          <form onSubmit={handleSubmit}>

{/* ----------------------------------------------- IDEAL PAYMENT METHODE SECTION ----------------------------------------------- */}

          <PaymentPanel
            paymentLogo='ideal.png'
            paymentTitle='iDeal'
            currentPaymentOption={paymentOption}
            paymentPrice='Gratis'
            onPaymentClick={(paymentTitle) => {
                setPaymentOption(paymentTitle)
                localStorage.setItem('paymentOption', paymentTitle)
              }}
            inputfields={
              <Col xs ={12} lg={6}>
              <div className="selecter" >
                <select onChange={handleChange} className="select" name="iDealBank">
                  <option value="" disabled="">Kies je bank</option>
                  <option value="ABN AMRO">ABN AMRO</option>
                  <option value="ASN Bank">ASN Bank</option>
                  <option value="Bunq">Bunq</option>
                  <option value="ING">ING</option>
                  <option value="Knab">Knab</option>
                  <option value="Moneyou">Moneyou</option>
                  <option value="Rabobank">Rabobank</option>
                  <option value="RegioBank">RegioBank</option>
                  <option value="SNS">SNS</option>
                  <option value="Triodos Bank">Triodos Bank</option>
                  <option value="van Lanschot">Van Lanschot</option>
                </select>
                <div className="select_arrow"></div>
              </div>
              </Col>
            } />


{/* ----------------------------------------------- CREDIT CARD PAYMENT METHODE SECTION ----------------------------------------------- */}

          <PaymentPanel
            currentPaymentOption={paymentOption}
            paymentLogo='creditcard.png'
            paymentTitle='Creditcard'
            paymentPrice='Gratis'
            onPaymentClick={(paymentTitle) => {
                setPaymentOption(paymentTitle)
                localStorage.setItem('paymentOption', paymentTitle)
              }}
            inputfields={
              <div className="input-wrapper">
              <label htmlFor="cc-number">Kaartnummer*</label>
              <FormHelperText>Dit nummer staat voor op je credit card</FormHelperText>
              <Field name='cardnumber'>
              {(props) => {
                const {field} = props
                return (
                  <input
                    {...field}
                    type="number"
                    name="cardnumber"
                    id="cardnumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={touched.cardnumber && errors.cardnumber ? "has-error" : null }
                  />
                )
              }}
              </Field>
              <Error touched={touched.cardnumber} message={errors.cardnumber} />


            <label htmlFor="cc-name">Naam kaarthouder*</label>
            <FormHelperText>De voor- en achternaam die op je creditcard staat</FormHelperText>
            <Field name='cardowner'>
            {(props) => {
              const {field} = props
              return (
                <input
                  {...field}
                  type="text"
                  name="cardowner"
                  id="cardowner"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className={touched.cardowner && errors.cardowner ? "has-error" : null }
                />
              )
            }}
            </Field>
            <Error touched={touched.cardowner} message={errors.cardowner} />

              <label>Vervaldatum*</label>
            <Row>
            <Col lg={3}>
            <div className="selecter-date">
                <select onChange={handleChange} className="select">
                  <option value="" disabled="">MM</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
                <div className="select_arrow"></div>
              </div>
            </Col>
            <Col lg={3}>
            <div className="selecter-date">
                <select onChange={handleChange} className="selecter">
                  <option value="" disabled="">JJJJ</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                </select>
                <div className="select_arrow"></div>
            </div>
            </Col>
            </Row>

              <label htmlFor="cc-name">CVC / CVV*</label>
                <FormHelperText>Het 3 delige cijfer achter op de credit card</FormHelperText>
              <input
                htmlFor="cc-csc"
                type="text small"
                name="cvc"
                placeholder="123"
                id="cvc"
                onChange={handleChange}
                onBlur={handleBlur}

                className={touched.cvc && errors.cvc ? "has-error" : null }
              />
              <Error touched={touched.cvc} message={errors.cvc} />
          </div>
            }
            />

{/* ----------------------------------------------- BANCONTACT PAYMENT METHODE SECTION ----------------------------------------------- */}

          <PaymentPanel
            paymentLogo='bancontact.svg'
            paymentTitle='Bancontact'
            currentPaymentOption={paymentOption}
            paymentPrice='Gratis'
            onPaymentClick={(paymentTitle) => {
                setPaymentOption(paymentTitle)
                localStorage.setItem('paymentOption', paymentTitle)
              }}
              inputfields={
                <div className="input-wrapper">
                <label htmlFor="bancontact_number">Kaartnummer*</label>
                <FormHelperText>Het 17 cijferige nummer voor op de kaart</FormHelperText>
                <Field name='bancontact_number'>
                {(props) => {
                  const {field} = props
                  return (
                    <input
                      {...field}
                      type="number"
                      name="bancontact_number"
                      id="bancontact_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={touched.bancontact_number && errors.bancontact_number ? "has-error" : null }
                    />
                  )
                }}
                </Field>
                <Error touched={touched.bancontact_number} message={errors.bancontact_number} />


              <label htmlFor="cc-name">Naam kaarthouder*</label>
              <FormHelperText>De voor- en achternaam die op de kaart staat</FormHelperText>
              <Field name='cardowner'>
              {(props) => {
                const {field} = props
                return (
                  <input
                    {...field}
                    type="text"
                    name="cardowner"
                    id="cardowner"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={touched.cardowner && errors.cardowner? "has-error" : null }
                  />
                )
              }}
              </Field>
              <Error touched={touched.cardowner} message={errors.cardowner} />

                <label>Vervaldatum*</label>
              <Row>
              <Col lg={3}>
              <div className="selecter-date">
                  <select onChange={handleChange} className="select">
                    <option value="" disabled="">MM</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <div className="select_arrow"></div>
                </div>
              </Col>
              <Col lg={3}>
              <div className="selecter-date">
                  <select onChange={handleChange} className="selecter">
                    <option value="" disabled="">JJJJ</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                  </select>
                  <div className="select_arrow"></div>
              </div>
              </Col>
              </Row>
            </div>
              }
              />


                <Button id="Button-Discount" onClick={() => setState({ shown: !currentState.shown })}>
                  + Kortingscode toevoegen
                </Button>

                <div>
                {currentState.shown ?
                  <Field name='discountcode'>
                  {(props) => {
                    const {field} = props
                    return (
                      <input
                        {...field}
                        type="text"
                        name="discountcode"
                        id="discountcode"
                        placeholder=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={touched.discountcode && errors.discountcode? "has-error" : null }
                      />
                    )
                  }}
                  </Field>
                  : ""}
                <Error touched={touched.discountcode} message={errors.discountcode} />
                </div>

              <div className="bottom_buttons">
              <Button disabled={step === 0} onClick={handleBack}>Vorige</Button>
              {/* Next & Finish button */}
              <Button type="submit" variant="contained" color="primary">
                {step === stepCount - 1 ? 'Finish' : 'Volgende'}
              </Button>
              </div>

              <Persist name='step-2' />
          </form>
        )}
      </Formik>
      </Col>
      </Row>
      </Container>
    </Fragment>
  );
 }
