import React, { Fragment } from 'react'

import { Row, Col } from 'react-bootstrap';

import '../../App.css';


const PaymentPanel = ({onPaymentClick, inputfields, currentPaymentOption, step, handleBack, handleNext, stepCount, children, paymentTitle, paymentPrice, paymentLogo}) => (
  <Fragment>


    <Row onClick={() => onPaymentClick(paymentTitle)} className="paymentbox">
      <Col xs={4} lg={2}>
        <img src={paymentLogo} alt="Payment Logo" className="paymenticon"/>
      </Col>
      <Col xs={2} lg={2}>
          <p className="paymentname">{paymentTitle}</p>
          <p className="free">{paymentPrice}</p>
      </Col>
      <Col xs={12} lg={9}>

        {paymentTitle === currentPaymentOption && inputfields}

      </Col>
    </Row>


  </Fragment>
)

export default PaymentPanel
