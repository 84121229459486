import React, { Fragment } from 'react';
import { Formik, Field } from 'formik';
import { Persist } from 'formik-persist';
import * as Yup from "yup";

import FormHelperText from '@material-ui/core/FormHelperText';
import { Row, Col, Container } from 'react-bootstrap';
import Button from '@material-ui/core/Button';


import Error from "../Error";
import '../../App.css';

const validationSchema = Yup.object().shape({
  password: Yup
    .string()
    .min(15, "Je wachtwoord moet minimaal 15 karakters lang zijn.")
    .max(255, "Je naam is te lang.")
})

export default function FormikForm({title, subTitle, step, stepCount, handleStepChange}){
  function getName(){
    const step1 = JSON.parse(localStorage.getItem("step-1"))
    return step1.values.firstname
  }
function getMail(){
    const step1 = JSON.parse(localStorage.getItem("step-1"))
    return step1.values.email
  }

  return (
    <Fragment>
      <Container>
        <Row>
      <Formik
        initialValues={{
          Password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {

          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
          }, 500);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit,  }) => (

          <form onSubmit={handleSubmit}>

            <div className="thankyou-headers">
              <h1>{getName()}{title}</h1>
              <h2>{subTitle}</h2>
            </div>


            <div className="account-box">
              <h3>Maak een account aan en volg je bestelling!</h3>
              <p id="accountinfo">Al je bestellingen kun je terugvinden in jouw account. Maak er snel eentje aan en houdt de status van je pakketjes bij.</p>
              <div className="input-row">
                <label htmlFor="password">Wachtwoord</label>

                <Field name='password'>
                {(props) => {
                  const {field} = props
                  return (
                    <input
                      {...field}
                      type="password"
                      name="password"
                      id="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={touched.password && errors.password ? "has-error" : null }
                    />
                  )
                }}
                </Field>
                <FormHelperText id="password-helper">Je wachtwoord moet minimaal 15 karakters lang zijn.</FormHelperText>
                <Error touched={touched.password} message={errors.password} />
              </div>
            </div>

            <Row>
            <Col>
              <div id="account-buttons">
                <Button onClick={() => window.open( 'https://www.tangoshoes.com/')} >Naar webshop</Button>
                <Button onClick={() => window.open( 'https://www.tangoshoes.com/account/login', '_self')}
                        variant="contained"
                        color="primary">
                        Maak account aan
                </Button>
              </div>
              </Col>
            </Row>

            <div className="thankyou-headers">
              <h1>Wat gaan we nu voor je doen?</h1>
              <h2>Dit doen we om jouw schoenen te bezorgen.</h2>
            </div>

            <div id="what-now-wrapper">
          <Row>
            <Col xs={12} lg={4}>
          <Row>
              <Col xs={1} lg={2}>
                <img src={'mail-icon.svg'} alt="mail-icon" className="instruction-icon"/>
              </Col>
              <Col xs={11} lg={8}>
                <h4>1. Orderbevestiging</h4>
                <p className="what-now-info">We sturen je een email zodra we de order hebben ontvangen. Dit kan enkele minuten duren. </p>
              </Col>
              </Row>
            </Col>


            <Col xs={12} lg={4}>
            <Row>
              <Col xs={1} lg={2}>
                <img src={'truck-icon.svg'} alt="truck-icon" className="instruction-icon"/>
              </Col>
              <Col xs={11} lg={8}>
                <h4>2. Bezorging</h4>
                <p className="what-now-info">Op het moment dat de bezorger jouw pakketje heeft, krijg je een mail met een track en trace code en de tijd wanneer de bezorger bij jou is.</p>
              </Col>
              </Row>
            </Col>

            <Col xs={12} lg={4}>
            <Row>
              <Col xs={1} lg={2}>
                <img src={'balloons-icon.svg'} alt="balloons-icon" className="instruction-icon"/>
              </Col>
              <Col xs={11} lg={8}>
                <h4>3. Genieten</h4>
                <p className="what-now-info">Ontvang je bestelling en genieten maar! Laat weten wat je van je bestelling vind en laat een review achter.</p>
              </Col>
              </Row>
            </Col>
          </Row>
          </div>

          <div className="thankyou-headers">
          <Row>
          <Col lg={7}>
              <h1>Show jouw #mytangoshoes en volg ons op instagram</h1>
          </Col>
          <Col lg={9}>
            <h2>
              Deel de foto’s van jouw Tango shoes met #mytangoshoes en wordt gefeatured op ons kanaal.
              Iedereen mag van mooie schoenen genieten! Krijg inspiratie van de outfits van andere en kijk hoe
              je jouw tango shoes het beste kunt matchen. Volg ons op @tangoshoes_com
            </h2>

          </Col>
          </Row>
          </div>

          <div className="socialwrapper">
            <Row>
              <Col xs={6} lg={3} >
                <img src={'Social-Images/social-post-1.jpg'} alt="balloons-icon" className="social-post"/>
              </Col>
              <Col xs={6} lg={3} >
                <img src={'Social-Images/social-post-2.jpg'} alt="balloons-icon" className="social-post"/>
              </Col>
              <Col xs={6} lg={3} >
                <img src={'Social-Images/social-post-3.jpg'} alt="balloons-icon" className="social-post"/>
              </Col>
              <Col xs={6} lg={3} >
                <img src={'Social-Images/social-post-4.jpg'} alt="balloons-icon" className="social-post"/>
              </Col>
            </Row>
          </div>

          <Row>

              <Button variant="contained" color="primary" className="social-button"
                onClick={() => window.open( 'https://www.instagram.com/explore/tags/mytangoshoes/')} >
                  Deel jouw schoenen
              </Button>

              <Button variant="contained" color="primary" className="social-button"
              onClick={() => window.open( 'https://www.instagram.com/tangoshoes_com/')}>
                Volg @Tangohoes
              </Button>

          </Row>

          <div className="thankyou-headers">
            <h1>Blijft op de hoogte!</h1>
            <h2>Schrijf je nu in voor onze nieuwsbrief en blijf op de hoogte van nieuwe schoenen en aanbiedingen!</h2>
          </div>

          <div id="newsletter">
            <label htmlFor="email">Email</label>
            <div className="input-row">
              <input
                type="email"
                name="email"
                id="email"
                value={getMail()}
                onBlur={handleBlur}
                onChange={handleChange}
                className={touched.email && errors.email ? "has-error" : null }
              />
              <Error touched={touched.email} message={errors.email} />
            </div>
          </div>
          <Button variant="contained" color="primary" id="newsletter_signup"
          onClick={() => window.open( 'https://www.tangoshoes.com/newsletter/subscribe',"_self")}>
            Inschrijven
          </Button>

          <Persist name='step-5' />
          </form>
        )}
          </Formik>
            </Row>
            </Container>
          </Fragment>
  )
}
