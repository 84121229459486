import React from 'react';
import './App.css';
import Multiform from "./multiform.js";

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <div>
        <img src={'logo.png'}
        alt="Logo Tango"
        id="logo"
        width="10%"
        onClick={() => window.open('https://www.tangoshoes.com',"_self")}/>
      </div>

      <Multiform/>

    </div>
  );
}

export default App;
