import React, { Fragment } from 'react'

// Material UI
import Box from '@material-ui/core/Box';


const StepperPanel = ({step, handleBack, handleNext, stepCount, children, handleSubmit}) => (
  <Fragment>

      <Box>{children}</Box>

  </Fragment>
)

export default StepperPanel
