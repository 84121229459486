import React from 'react'

// Material UI
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

export default ({activeStep, steps}) =>(
  <Stepper activeStep={activeStep} alternativeLabel>
    {steps.map(label => (
    <Step key={label}>
      <StepLabel>{label}</StepLabel>
    </Step>
    ))}
  </Stepper>
)
