import React, { Fragment } from 'react'

// Material UI
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';



const Winkelwagen = () => (
  <Fragment>
  <div className="shoppingcardWrapper">

    <h3>Winkelwagen</h3>
    <div className='row'>
      <div className='column'>
        <div className='blue-column'>
          <img src={'./blackshoe.jpg'} alt="blackshoe" className="productimage"/>
        </div>
      </div>
      <div className='column'>
        <div className='blue-column'>
          <p className="productname">Kady fat ag-10</p>
          <p className="productprice">€99,95</p>
        </div>
      </div>
    </div>

    <hr className="cartline"/>

    <div className='row'>
      <div className='column'>
        <div className='blue-column'>
          <img src={'./brownshoe.jpg'} alt="brownshoe" className="productimage" />
        </div>
      </div>
      <div className='column'>
        <div className='blue-column'>
          <p className="productname">Pleun 40-C</p>
          <p className="productprice">€129,95</p>
        </div>
      </div>
    </div>

    <hr className="cartline"/>

    <div className='row'>
      <div className='column'>
        <div className='blue-column'>
          <p className="productprice">Verzendkosten</p>
        </div>
      </div>
      <div className='column'>
        <div className='blue-column'>
          <p className="free">Gratis</p>
        </div>
      </div>
    </div>

    <div className='row'>
      <div className='column'>
        <div className='blue-column'>
          <p className="productprice">Totaalbedrag</p>
          <FormHelperText>Incl. 21% BTW</FormHelperText>
        </div>
      </div>
      <div className='column'>
        <div className='blue-column'>
          <p className="productprice">€229,90</p>
        </div>
      </div>
    </div>

    <Button className="buttonLinkShopping">
      Wijzig winkelwagen
    </Button>
    </div>
    <div className="quality-mark-wrapper">
      <img src={'./Thuiswinkel.png'} alt="Thuiswinkel keurmerk" className="quality-mark"/>
      <img src={'./webshopkeurmerk.png'} alt="Thuiswinkel keurmerk" className="quality-mark"/>
      <img src={'./klantenvertellen.png'} alt="Thuiswinkel keurmerk" className="quality-mark"/>
    </div>

  </Fragment>
)

export default Winkelwagen
