import React, { Fragment } from 'react';

// Material UI

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Internal
import StepOne from "../CheckoutSteps/step1.js";
import StepTwo from "../CheckoutSteps/step2.js";
import StepThree from "../CheckoutSteps/step3.js";
import StepFour from "../CheckoutSteps/step4.js";
import StepFive from "../CheckoutSteps/step5.js";

import StepperBar from '../StepperBar';
import StepperPanel from '../Panel';

function getSteps() {
  return ['Gegevens', 'Betaalwijze', 'Overzicht', 'Betaling', 'Bedankt'];
}

const CustomStepper = ({step, handleReset, handleBack, handleNext, onSubmit, handleStepChange}) => {
  const renderStep = () => {
    switch (step) {
      case 0:
        return (<StepOne stepCount={getSteps().lenght} step={step} handleNext={handleNext} title='Waar mogen we jouw schoenen bezorgen?' subTitle='Vul de gegevens hieronder in.' />)
      case 1:
        return (<StepTwo stepCount={getSteps().lenght} handleNext={handleNext} handleBack={handleBack} title='Op welke manier had je graag willen betalen?' subTitle='Kies een van de opties hieronder.' />)
      case 2:
        return (<StepThree stepCount={getSteps().lenght} handleNext={handleNext} handleBack={handleBack} handleStepChange={handleStepChange} title='Een overzicht van jouw bestelling' subTitle='Even alles op een rijtje.' />)
      case 3:
        return (<StepFour stepCount={getSteps().lenght} handleNext={handleNext} handleBack={handleBack} handleStepChange={handleStepChange} title='Externe Mollie koppeling' subTitle='Beschikbaar wanneer Tango hun Mollie account koppelt.'/>)
      case 4:
        return (
          (<StepFive handleStepChange={handleStepChange} title=', bedankt voor je bestelling!' subTitle='Jouw schoenen worden zo snel mogelijk geleverd.' />)
        )
      default:
        return 'Uknown stepIndex';
    }
  }

  return (
    <Fragment>
      {/* Renders the stepper names and numbers */}
      <StepperBar activeStep={step} steps={getSteps()} />

      {/* Renders the final step or the StepperPanels */}
      <div>
        {step === getSteps().length ? (
          <div className="bottom_buttons">
          {/* className={classes.instructions} */}
            <Typography>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <StepperPanel step={step} handleBack={handleBack} handleNext={handleNext} stepCount={getSteps().lenght}>
            {renderStep()}
          </StepperPanel>
        )}
      </div>
    </Fragment>
  )
}

export default CustomStepper
