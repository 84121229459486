import React, { Fragment } from 'react';

import { Formik, Field } from 'formik';
import { Persist } from 'formik-persist';
import * as Yup from "yup";
import { Row, Col, Container } from 'react-bootstrap';

import Button from '@material-ui/core/Button';

// Internal
import Error from "../Error";
import Winkelwagen from "../Shoppingcard/index.jsx";
import '../../App.css';

const validationSchema = Yup.object().shape({
  firstname: Yup
    .string()
    .min(1, "Je naam is te kort.")
    .max(255, "Je naam is te lang.")
    .matches(/^([^0-9]*)$/, "Geen cijfers toegestaan")
    .required("Dit veld moet verplicht ingevuld worden"),
  lastname: Yup
    .string()
    .min(1, "Je naam is te kort.")
    .max(255, "Je naam is te lang.")
    .matches(/[a-zA-Z]+/, "Geen cijfers toegestaan")
    .required("Dit veld moet verplicht ingevuld worden"),
  postalcode: Yup
    .string()
    .min(1, "Je naam is te kort.")
    .max(255, "Je naam is te lang.")
    .matches(/\d{4}[A-Z]{2}/, "Voer een geldige postcode in, bijv. 1234AB" )
    .required("Dit veld moet verplicht ingevuld worden"),
  housenumber: Yup
    .string()
    .min(1, "Je naam is te kort.")
    .max(255, "Je naam is te lang.")
    .matches(/^\d{1,3}[a-zA-Z]?$/, "Voer een geldig huisnummer in, bijv. 12, 12A, 123 of 123A")
    .required("Dit veld moet verplicht ingevuld worden"),
  street: Yup
    .string()
    .min(1, "Je naam is te kort.")
    .max(255, "Je naam is te lang.")
    .matches(/^([^0-9]*)$/, "Geen cijfers toegestaan")
    .required("Dit veld moet verplicht ingevuld worden"),
  city: Yup
    .string()
    .min(1, "Je naam is te kort.")
    .max(255, "Je naam is te lang.")
    .matches(/[a-zA-Z]+/, "Geen cijfers toegestaan")
    .required("Dit veld moet verplicht ingevuld worden"),
  email: Yup
    .string()
    .email("Voer een geldig email adres in, bijv. mail@adres.nl")
    .max(255, "Dit mailadres is te lang")
    .required("Dit veld moet verplicht ingevuld worden")
})




export default function FormikForm({title, subTitle, handleNext, step, stepCount, handleBack}) {

  return (
    <Fragment>
      <Container>
      <Row>

        <Col xs={{ span: 12, offset: 0 }} lg={{ span: 4, offset: 0 }}>
          <Winkelwagen/>
        </Col>

        <Col lg={{ span: 8, offset: 0 }}>
          <h1>{title}</h1>
          <h2>{subTitle}</h2>

      <Formik
        initialValues={{
          firstname: "",
          insertion: "",
          lastname: "",
          postalcode: "",
          housenumber:"",
          street:"",
          city:"",
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
            handleNext()
          }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue}) => (
          <form onSubmit={handleSubmit} autoComplete="on">

  {/*  ------------------------------------------------------------  NAAM VAN BEZOEKERS --------------------------------------------  */}
            <div className="input-row">
              <label htmlFor="firstname">Voornaam*</label>
              <Field name='firstname'>
              {(props) => {
                const {field} = props
                return (
                  <input
                    {...field}
                    type="text"
                    name="firstname"
                    id="firstname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={touched.firstname && errors.firstname ? "has-error" : null }
                  />
                )
              }}
              </Field>
              <Error touched={touched.firstname} message={errors.firstname} />
            </div>

            <div className="input-row">
              <label
                htmlFor="insertion">Tussenvoegsel</label>
                <Field name='insertion'>
                {(props) => {
                  const {field} = props
                  return (
                  <input
                  {...field}
                    type="text small"
                    name="insertion"
                    id="insertion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
              )
            }}
            </Field>
            <Error/>
            </div>

            <div className="input-row">
              <label htmlFor="lastname">Achternaam*</label>
              <Field name='lastname'>
              {(props) => {
                const {field} = props
                return (
                  <input
                    {...field}
                    type="text"
                    name="lastname"
                    id="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={touched.lastname && errors.lastname ? "has-error" : null }
                  />
                )
              }}
              </Field>
              <Error touched={touched.lastname} message={errors.lastname} />
            </div>

  {/*  ----------------------------------------------------------------------  ADRES VAN BEZOEKERS ----------------------------------------------------------------------  */}
            <div className="input-row">
              <label htmlFor="postal-code">Postcode*</label>
              <Field name='postalcode'>
              {(props) => {
                const {field} = props
                return (
                  <input
                    {...field}
                    type="text small"
                    name="postalcode"
                    id="postalcode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={touched.postalcode && errors.postalcode ? "has-error" : null }
                  />
                )
              }}
              </Field>
              <Error touched={touched.postalcode} message={errors.postalcode} />
            </div>

            <div className="input-row">
            <label htmlFor="housenumber">Huisnummer*</label>
            <Field name='housenumber'>
            {(props) => {
              const {field} = props
              return (
                <input
                  {...field}
                  type="text small"
                  name="housenumber"
                  id="housenumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className={touched.housenumber && errors.housenumber ? "has-error" : null }
                />
              )
            }}
            </Field>
              <Error touched={touched.housenumber} message={errors.housenumber} />
            </div>

            <div className="input-row">
              <label htmlFor="street">Straatnaam*</label>
              <Field name='street'>
              {(props) => {
                const {field} = props
                return (
                  <input
                    {...field}
                    type="text"
                    name="street"
                    id="street"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={touched.street && errors.street ? "has-error" : null }
                  />
                )
              }}
              </Field>
              <Error touched={touched.street} message={errors.street} />
            </div>

            <div className="input-row">
              <label htmlFor="city">Plaatsnaam*</label>
              <Field name='city'>
              {(props) => {
                const {field} = props
                return (
                  <input
                    {...field}
                    type="text"
                    name="city"
                    id="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={touched.city && errors.city ? "has-error" : null }
                  />
                )
              }}
              </Field>
              <Error touched={touched.city} message={errors.city} />
            </div>

  {/*  ----------------------------------------------------------------------  EMAIL VAN BEZOEKERS ----------------------------------------------------------------------  */}

  <div className="input-row">
        <label htmlFor="email">Email*</label>
    <Field name='email'>
    {(props) => {
      const {field} = props
      return (
        <input
          {...field}
          type="email"
          name="email"
          id="email"
          onBlur={handleBlur}
          onChange={handleChange}
          className={touched.email && errors.email ? "has-error" : null }
        />
      )
    }}
    </Field>
    <Error touched={touched.email} message={errors.email} />
  </div>


            <p>* = verplicht veld</p>

            <div className="bottom_buttons">
            <Button disabled={step === 0} onClick={handleBack}>Vorige</Button>
            {/* Next & Finish button */}
            <Button type="submit" variant="contained" color="primary">
              {step === stepCount - 1 ? 'Finish' : 'Volgende'}
            </Button>

            </div>

            <Persist name='step-1' />
          </form>
        )}
      </Formik>
      </Col>
      </Row>
      </Container>
    </Fragment>
  );
}
