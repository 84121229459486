import React, { Fragment } from 'react';
import { Formik } from 'formik';
import { Persist } from 'formik-persist';

import moment from 'moment';
import 'moment/locale/nl';

import Winkelwagen from '../Shoppingcard/index.jsx';
import Button from '@material-ui/core/Button';

import { Row, Col, Container } from 'react-bootstrap';

import '../../App.css';

export default function FormikForm({title, subTitle, step, stepCount, handleStepChange, handleNext, handleBack}){
  function getName(){
    const step1 = JSON.parse(localStorage.getItem("step-1"))
    return step1.values.firstname + "\u00a0" + step1.values.insertion + "\u00a0" + step1.values.lastname
  }
  function getAdres(){
    const step1 = JSON.parse(localStorage.getItem("step-1"))
    return step1.values.street + "\u00a0" + step1.values.housenumber
  }
  function getPostal(){
    const step1 = JSON.parse(localStorage.getItem("step-1"))
    return step1.values.postalcode + "\u00a0" + step1.values.city
  }
  function getCardowner(){
    const step2 = JSON.parse(localStorage.getItem("step-2"))
    return step2.values.cardowner
  }
  function getCardNumber(){
    const step2 = JSON.parse(localStorage.getItem("step-2"))
    return step2.values.cardnumber
  }
  function getPaymentOption(){
    const paymentOption = localStorage.getItem("paymentOption")
    console.log(paymentOption)
    return paymentOption
  }
  function getBank(){
    const step2 = JSON.parse(localStorage.getItem("step-2"))
    return step2.values.iDealBank
  }
  function getDiscountcode(){
    const step2 = JSON.parse(localStorage.getItem("step-2"))
    return step2.values.discountcode
  }




  const date = moment(new Date()).add(1,'days');

  return (
    <Fragment>
    <Container>
      <Row>
        <Col lg={{ span: 4, offset: 0 }}>
          <Winkelwagen/>
        </Col>

    <Col lg={{ span: 8, offset: 0 }}>
      <Formik
        initialValues={{
          firstname: "",
          insertion: "",
          lastname: "",
          postalcode: "",
          housenumber:"",
          email: "",
        }}
        onSubmit={(values) => {

          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
          }, 500);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (

          <form onSubmit={handleSubmit}>
            <h1>{title}</h1>
            <h2>{subTitle}</h2>
              <h4>Gegevens</h4>
              <h5>Bezorg- en factuuradres</h5>
            <div className="information">
              <p>{getName()}</p>
              <p>{getAdres()} </p>
              <p>{getPostal()} </p>
              <Button className="buttonLinkInformation" onClick={() => handleStepChange(0)}>
                Wijzig gegevens
              </Button>
            </div>

            <h4>Bezorging</h4>
            <h5>Datum en tijd</h5>
            <div className="information">
              <p> {moment(date).locale('nl').format('dddd D MMMM YYYY')}</p>
              <p> 18:00 - 22:00 </p>
              <p> PostNL </p>
            </div>
              <h4>Betaalmethode</h4>
              <h5>Manier van betalen</h5>
            <div className="information">
              <p>{getPaymentOption()}</p>
              <p>{getBank()}</p>
              <p>{getCardowner()}</p>
              <p>{getCardNumber()}</p>
              <p id="kortingscode"> {getDiscountcode()}</p>
              <Button className="buttonLinkInformation" onClick={() => handleStepChange(1)}>
                Wijzig betaalmethode
              </Button>
            </div>

            <div className="bottom_buttons">
            <Button disabled={step === 0} onClick={handleBack}>Vorige</Button>
            {/* Next & Finish button */}
            <Button variant="contained" color="primary" onClick={handleNext}>
              {step === stepCount - 1 ? 'Finish' : 'Volgende'}
            </Button>
            </div>

            <Persist name='step-3' />
         </form>
       )}
    </Formik>
    </Col>
    </Row>
    </Container>
    </Fragment>
  )
}
