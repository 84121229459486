import React, { Component, Fragment } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import CustomStepper from './components/CustomStepper'

//STYLES//
// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '50%',
//   },
//   backButton: {
//     marginRight: theme.spacing(1),
//   },
//   instructions: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//   },
// }));

class Multiform extends Component {
  state = {
    step: 0,
    firstname: "",
    insertion: "",
    lastname: "",
    postalcode: "",
    housenumber:"",
    email: "",
    cardnumber: "",
    cardowner: "",
    cvc:"",
    paymentoption: ""
  }

  handleStepChange = (step) => this.setState({step})

  handleNext = () => {
    this.setState((prevState, props) => {
      return {step: prevState.step + 1};
    }, () => {
      this.handleStepChange(this.state.step)
    })
  };

  handleBack = () => {
    this.setState((prevState, props) => {
      return {step: prevState.step - 1};
    }, () => {
      this.handleStepChange(this.state.step)
    })
  };

  handleReset = () => {
    this.setState({step:0});
  };

  handleStepChange = (step) => {
    this.setState({step})
  }

  componentDidMount = () => {
    var pathname = window.location.pathname;
    var step = pathname.replace(/\\|\//g,'')

    this.setState({step: Number(step)})
  }

  render() {
    return (
      <Fragment>
        <CustomStepper
          step={this.state.step}
          onSubmit={() => console.log("submitting form...")}
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          handleReset={this.handleReset}
          handleStepChange={this.handleStepChange}
        />
      </Fragment>
    );
  }
}

export default Multiform
